@import "~dyl-components/config.scss";

.ui.bottom.center.popup.RouteSection__popup, .ui.top.center.popup.RouteSection__popup {
    background-color: $secondary-6-b;

    .ui.ui.multiple.dropdown {
        background-color: $grey-8;
        border: 1px solid $grey-1;
    }
}

.Routing__value {
    max-width: 125px;
}

