@import "~dyl-components/config.scss";
$margin: 2.857142857142857em;
$animation-timing: 0.1s;
.ui.left.sidebar {
  width: 22.857142857142858rem;
}
.ui.left.visible.sidebar {
  box-shadow: none;
}
.ui.left.sidebar.DYL2Menu {
  overflow: hidden !important;
  flex-direction: column;
  display: flex;
  background-image: linear-gradient(200deg,$primary-table-7  0%, $primary-table-6 100%);

  &--open {
    width: 9.2em + $margin;
    transition: width $animation-timing;
  }

  &--closed {
    width: 4.8em - $margin / 4;
    transition: width $animation-timing;
  }

  &--scroll {
    overflow-y: scroll !important;
    scrollbar-width: none;
  }

  &--scroll::-webkit-scrollbar {
    display: none;
  }

  .item.DYL2Menu__item {
    padding-left: 1.53em;
    padding-top: 10px;
    padding-bottom: 10px;

    &:hover {
      background-color: $grey-1;
    }
  }

  .active.item.item.DYL2Menu__item {
    background-color: $primary-text-2;
  }

  .item.DYL2Menu__subitem {
    &--active {
      background-color: $grey-1;
    }
  }


  .ui.image.DYL2Menu__controls {
    &--office-view {
      &:hover {
        cursor: pointer;
      }

      &--open2 {
        padding: 0;
        margin-top: 0.6em;
        margin-bottom: 1.45em;
      }
      margin-bottom: 0.4em;
    }

    &--chat-room {
      &:hover {
        cursor: pointer;
      }

      &--open2 {
        padding: 0;
        margin-top: 0.6em;
        margin-bottom: 1.45em;
      }
      margin-bottom: 1.3em;
    }
  }
}

.ui.divider.Menu__Divider {
  margin: 0.1em 0;
}

.ui.divider:not(.vertical):not(.horizontal).Menu__Divider {
  border-top: 1px solid $primary-table-4;
}

.Menu__icon {
  padding-right: 1.857142857142857em;
}

.ui.container.stackable.grid.DYL2MenuGrid {
  .ui.image.FavRecIcon {
    width: 30px;
  }
}

.DYL2Logo {
  margin-left: 1em;
  margin-top: 1em;
  margin-bottom: 1.5em;
}

.LeftSideBarSpan {
  font-size: 12;
}

.ui.icon.LeftSideBarIcon {
  padding-left: 0.2em;
  line-height: 0em;
}

.pushable:not(body) {
  transform: none;
}

.pushable:not(body) > .ui.sidebar.DYL2Menu,
.pushable:not(body) > .fixed,
.pushable:not(body) > .pusher:after {
  position: fixed;
}
