@import "dyl-components/config.scss";

.UserExtensions {
    .UserExtensionsToolbar {
        display: flex;

        &__searchContainer {
            flex: 1;

            .ui.input {
                width: 100%;
            }
        }

        &__button.ui.button {
            margin-left: auto;
            color: $white;
            background-color: $primary-table-6;
        }

        .field.SearchBar__SearchInput.UserExtensions__search{
            .ui.left.icon.input{
                width: 375px;
            }
        }
    }

    .ui.table {
        margin-bottom: 0px;
        .icon.fa-solid.fa-music{
            color: $primary;
            cursor: pointer;
        }
    }

    .UserExtensions__cancelButton {
        color: $primary-error-2;
    }

    .UserExtensions__buttonSeparator {
        color: $grey-3;
    }
    
    .ui.fixed.table td, .ui.fixed.table th {
        overflow: visible;
    }

    .ui.table tbody tr.Table__row.CollapsibleTableRow__subrowContent > td:first-of-type {
        border-left: unset;
    }

    .ui.table tbody tr.Table__row.CollapsibleTableRow__subrowContent > td {
        background: unset;
    }

    &__clippedTrigger {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 245px;
    }

    &__clippedContent {
        max-height: 128px;
        overflow: auto;
    }

    &__closeIcon {
        color: $grey-2
    }

}