@import 'dyl-components/config.scss';

.IvrPromptsModal__form {
    .IvrPromptsModal__controllerContainer {
        display: flex;
        gap: 25px;
        align-items: center;

        .field {
            flex: 3
        }
        .field:last-child.IvrPrompts__ruleField {
            margin-bottom: 1em;
        }
    }

    .IvrPromptsModal__rulesContainer {
        margin-top: 25px;

        .IvrPrompts__ruleContainer {
            display: flex;
            width: 100%;
            gap: 25px;
            align-items: center;

            .IvrPrompts___elementContainer {
                height: auto;
                display: flex;
                justify-content: center;
                align-items: center;

                .IvrPrompts__invisibleIcon {
                    visibility: hidden;
                }
            }

            .IvrPrompts__timesContainer {
                flex: 1;
                max-width: 42%; 
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;

                .IvrPrompts__timeControllersContainer{
                    flex: 1;
                    width: 100%;
                    gap: 20px;
                    display: flex;

                    .IvrPrompts__timeField {
                        flex: 1;
                    }
                }

                .IvrPrompts__errorLabel {
                    white-space: normal;
                    background-color: $primary-error-4;
                    color: $primary-error-1;
                    border: $primary-error-1 1px solid;
                    padding: 7px 25px 7px 25px;
                    margin-bottom: 14px;
                    margin-top: 0;
                }
            }
    
            .IvrPrompts__timeframeField {
                flex: 1;
                max-width: 30%;

                .ui.multiple.dropdown>.label {
                    background-color: $primary-table-9;
                    box-shadow: 0 0 0 1.5px $secondary-7 inset;

                    i.icon.delete:before {
                        color: $primary-button-1;
                    }
                }
            }
    
            .IvrPrompts__timeframeField {
                margin: 0 0 1em;
            }
        }

        .IvrPromptsModal__blackIcon {
            color: $grey-1;
        }

        .IvrPromptsModal__redIcon {
            color: $primary-error-2;
        }

        .IvrPromptsModal__greyIcon {
            color: $grey-3;
        }
    }
}