@import 'dyl-components/config.scss';

.UserExtensionModal__form {
    .UserExtensionModal__controllerContainer {
        display: flex;
        gap: 25px;

        .field {
            flex: 1
        }

        .UserExtensions__ruleField {
            max-width: 23%;
        }

        .UserExtensionsModal__emailField {
            div.ui.multiple.selection.dropdown>.label:not(.UserExtension--error) {
                background-color: $grey-5;
                color: $grey-1;
            }

            div.ui.multiple.selection.dropdown>.label.UserExtension--error {
                background-color: $secondary-1-b;
                color: $primary-error-1;
            }
        }
    }
}