@import '~dyl-components/config.scss';

.AudioModal__form {
    .AudioModal__controllerContainer {
        display: flex;
        gap: 20px;

        .field {
            flex: 1;
        }

        .field.disable--selects {
            .ui.multiple.dropdown>.label {
                i.icon.delete:before {
                    display: none;
                }
            } 
        }

        .ui.multiple.dropdown>.label {
            background-color: $primary-table-9;
            box-shadow: 0 0 0 1.5px $secondary-7 inset;

            i.icon.delete:before {
                color: $primary-button-1;
            }
        } 
    }
}

.AudioModal__controllerContainer--Attachment--required{
    color: $primary-error-1;
}