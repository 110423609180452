.Checkout__payment {
    position: relative;
    overflow: hidden;
    left: 4em;
    width: 100%;
    max-width: 1000px;
    padding-top: 350px;
    min-height: 90vh;

    iframe {
        position: absolute;
        top: 0;
        left: 3em;
        bottom: 0;
        right: 0;
        border: none;
        width: 100%;
        height: 100%;
    }
}

.field.Checkout__payment-method {
    .ui.dropdown {
        .text {
            width: 100%;
            text-align: left;
        }
    }
}
