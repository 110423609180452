.LatestNoteContent {
  display: table;
  table-layout: fixed;
  width: 100%;

  span {
    display: table-cell;
    overflow-wrap: break-word;
    text-overflow: ellipsis;
  }
}

.LatestNotePopup {
    text-align: right;
}
